.footer-box {
  width: 100%;

  .help-box {
    background: #c60501;
    height: 230px;

    .content {
      width: 100%;
      max-width: 1200px;
      height: 210px;
      margin: 0 auto;
      padding-top: 20px;
      padding: 20px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      .help-item {
        width: 20%;
        min-width: 173px;
        color: #ffffff;

        .help-title {
          font-size: 12px;
          font-weight: 700;
          line-height: 22px;
        }
        .child-list {
          list-style: disc;
          list-style-position: inside;

          a {
            font-size: 12px;
            color: #fdfdfd;
            line-height: 16px;
            font-family: Helvetica,Arial,sans-serif;
          }
          a:hover {
            text-decoration: underline;
          }

          .help-child-item {
            font-size: 12px;
            color: #fdfdfd;
            line-height: 16px;
            font-family: Helvetica,Arial,sans-serif;
            cursor: pointer;
          }
          .help-child-item:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .information-box {
    text-align: center;
    margin: 0 auto;
    padding: 40px 0 100px 0;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .logo {
        width: 250px;
        height: 82px;
        line-height: 82px;
        border-right: 1px solid #ddd;
      }

      .payment-title {
        height: 82px;
        line-height: 82px;
        display: inline-block;
        padding: 0 15px;
        font-size: 12px;
        color: #333;
        font-weight: 600;
      }
      .payment-logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .payment-item {
          margin-right: 4px;
        }
      }
    }
  }

  .honour-list {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    width: 750px;
    margin: 0 auto;

    .honour-item {
      margin: 0 3px;
    }
  }

  .text {
    height: 80px;
    font-size: 12px;
    color: #9b9b9b;
    text-align: center;
    padding: 0 180px;
  }
}