@import './_variables.scss';

.top-announcement {
  display: block;
  background-color: #000;
  padding: 4px 0;

  .announcement {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .items {
      font-size: 16px;
      color: #fff;
    }

    .items.catching {
      color: #FD8C26;
      font-weight: bold;
      margin-right: 10px;
    }

    .buyNow {
      width: 120px;
      height: 22px;
      font-size: 14px;
      padding: 0;
      margin-left: 22px;
      background-color: #FD8C26;
      color: #fff;
      border-color: #FD8C26;
    }
  }
}

.navbar {
  width: 100%;
  height: 114px;

  .navbar-box {

    .navbar-content {
      max-width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .logo-box {
        width: 293px;
        padding-top: 21px;
        padding-left: 19px;

        .site-logo {
          width: 100%;
          max-width: 251px;
          height: auto;
        }
      }

      .right-box {
        width: calc(100% - 293px);

        .link-box {
          height: 48px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 8px;

          .shop-share {
            color: #fff;
            display: inline-flex;
            align-items: center;

            .thread-item {
              display: inline-block;
              width: 22px;
              height: 22px;
              border-radius: 50%;
              overflow: hidden;
              position: relative;
              background-color: #000;

              &-icon {
                width: 42px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .ext-link-svg {
              width: 24px;
              height: 24px;
              vertical-align: top;
              margin-right: 0;
              cursor: pointer;
              margin-left: 4px;
            }
          }

          .track-order,
          .help-link {
            font-size: 13px;
            color: #9b9b9b;
            padding-right: 5px;
            padding-left: 3px;
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              color: #e60917;
              padding-left: 6px;
              white-space: nowrap;

              .icon-down {
                color: #9b9b9b;
                font-size: 10px;
              }
            }
          }

          .help-link {
            position: relative;

            .nav {
              border: 1px solid transparent;
              padding-right: 4px;
            }

            .menu {
              display: none;
              position: absolute;
              top: 16px;
              left: 6px;
              width: 120px;
              background-color: #fff;
              z-index: 100;
              flex-direction: column;
              padding: 5px 0;
              border: 1px solid #9a9a9a;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              border-top-right-radius: 5px;

              a {
                color: #9b9b9b;
                display: block;
                padding: 0 5px;
                font-size: 13px;
                height: 20px;
                line-height: 20px;
              }

              a:hover {
                color: #e60500;
                background: #f2f2f2;
              }
            }
          }

          .help-link:hover {
            z-index: 99;

            .nav {
              border: 1px solid #9a9a9a;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }

            .menu {
              display: block;
            }
          }

          .help-link::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 7px;
            width: 53px;
            height: 1px;
            background-color: #fff;
            z-index: 9;
          }

          .app-link {
            font-size: 13px;
            color: #9b9b9b;
            padding-right: 5px;
            padding-left: 3px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .icon-app {
              color: #9b9b9b;
              font-size: 20px;
              padding-left: 6px;
            }

            span {
              color: #e60917;
              text-decoration: underline;
              white-space: nowrap;
            }
          }
        }

        .top-head {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          .search-bar {
            position: relative;
            width: 50%;
            height: 30px;
            border: 3px solid #d50b18;
            box-sizing: content-box;
          }

          .tools-bar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-left: 10px;
            padding-right: 20px;

            .my-cart-box,
            .wish-list,
            .account {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-left: 11px;
              cursor: pointer;

              .tool-icon {
                display: block;
                width: 40px;
                height: 40px;
                font-size: 40px;
                color: #e60917;
              }

              .cart-info {
                width: 35px;
                padding-left: 5px;

                .cart-num {
                  width: 30px;
                  height: 16px;
                  font-size: 12px;
                  background: #FD8B25;
                  text-align: center;
                  color: #fff;
                  line-height: 16px;
                  border-radius: 20px;
                }

                .cart-num.gray {
                  background: #c9c9c9;
                }

                .cart-title {
                  color: #666666;
                  font-size: 13px;
                  line-height: 17px;
                  text-align: center;
                }
              }

              .account-info {
                padding-left: 5px;

                .login-link {
                  min-width: 86px;
                  color: #666666;
                  font-size: 13px;
                  text-align: center;
                  display: flex;
                  justify-content: space-between;

                  .signIn,
                  .join {
                    cursor: pointer;
                  }

                  .signIn:hover,
                  .join:hover {
                    color: #e60500;
                  }
                }

                .account-title {
                  font-size: 13px;
                  display: inline-block;
                  color: #666666;
                }
              }
            }

            .my-cart:hover,
            .wish-list:hover,
            .account:hover {
              .cart-title {
                color: #e60500;
              }
            }

            .my-cart-old {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-left: 20px;
              cursor: pointer;

              .tool-icon {
                display: block;
                width: 40px;
                height: 40px;
                font-size: 40px;
                color: #e60917;
              }

              .cart-info {
                width: 35px;
                padding-left: 5px;

                .cart-num {
                  width: 30px;
                  height: 16px;
                  font-size: 12px;
                  background: #d50b17;
                  text-align: center;
                  color: #fff;
                  line-height: 16px;
                  border-radius: 20px;
                }

                .cart-num.gray {
                  background: #c9c9c9;
                }

                .cart-title {
                  color: #666;
                  font-size: 13px;
                  line-height: 17px;
                  text-align: center;
                }
              }
            }

            .my-cart-box {
              position: relative;
              border: 1px solid transparent;
              background: #fff;
              z-index: 10;
              padding: 0 8px;

              .my-cart {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                cursor: pointer;
              }

              .my-cart-hover-box {
                display: none;
                position: absolute;
                top: 42px;
                left: -1px;
                min-width: 220px;
                min-height: 70px;
                background-color: #fff;
                border: 1px solid #DDDDDD;
                border-radius: 0 5px 5px 5px;
                padding: 16px 0;
                box-sizing: border-box;
                box-shadow: 0px 6px 16px 0px rgba(0, 11, 0, 0.08);
                z-index: 99;

                .empty-cart {
                  font-size: 12px;
                  font-weight: 400;
                  color: #666666;
                  line-height: 16px;
                  padding: 0 16px;
                }

                .minicar-box {
                  width: 340px;

                  .minicar-list {
                    position: relative;
                    border-bottom: 1px solid #DDDDDD;
                    margin-bottom: 16px;
                    max-height: 370px;
                    overflow-y: auto;
                    padding-left: 16px;
                    padding-right: 16px;

                    &-item {
                      display: flex;
                      align-items: center;
                      margin-bottom: 16px;

                      &-image {
                        width: 58px;
                        height: 58px;
                        flex-shrink: 0;
                        margin-right: 16px;
                      }

                      &-info {
                        flex: 1;
                        overflow: hidden;

                        .goodsName {
                          font-size: 12px;
                          color: #666666;
                          width: 100%;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          overflow: hidden;
                        }

                        .goodsValue {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          margin-top: 8px;

                          .qty {
                            font-size: 14px;
                            color: #333333;
                          }

                          .price {
                            font-size: 14px;
                            font-weight: bold;
                            color: #D70B17;
                          }
                        }
                      }
                    }
                  }

                  // .minicar-list::-webkit-scrollbar {

                  // }
                }
              }

              .minicar-total {
                display: flex;
                justify-content: space-between;
                margin-top: 15px;
                // margin-bottom: 16px;
                padding: 0 16px;
                align-items: center;

                .selectItems {
                  font-size: 14px;
                  color: #333333;
                  line-height: 16px;
                  flex-shrink: 0;
                }

                .totalValue {
                  font-size: 24px;
                  font-weight: bold;
                  color: #D70B17;
                }
              }

              .valueTips {
                color: #afafaf;
                font-size: 14px;
                margin: 8px 0 16px;
                text-align: right;
                padding-right: 16px;
              }

              .minicar-config {
                display: flex;
                justify-content: center;

                .minicar-config-btn+.minicar-config-btn {
                  margin-left: 8px;
                }

                .viewcar-btn {
                  border-color: #D70B17;
                  font-size: 16px;
                  font-weight: bold;
                  color: #D70B17;
                }

                .checkout-btn {
                  background-color: #FD8B25;
                  font-size: 16px;
                  font-weight: bold;
                  color: #fff;
                }
              }
            }

            .my-cart-box:hover {
              border: 1px solid #DDDDDD;
              border-radius: 5px 5px 0 0;
              border-bottom: 1px solid transparent;

              .my-cart-hover-box {
                display: block;
              }
            }

            .my-cart-box:hover::after {
              display: block;
            }

            .my-cart-box::after {
              display: none;
              content: "";
              position: absolute;
              left: -1px;
              bottom: -3px;
              background-color: #fff;
              width: 100%;
              height: 3px;
              border-left: 1px solid #DDDDDD;
              border-right: 1px solid #DDDDDD;
            }

            .account {
              position: relative;
              background: #fff;
              padding-left: 0;
              padding-bottom: 1px;
              margin-left: 20px;
              border: 1px solid transparent;
              z-index: 10;

              .more_acc {
                position: absolute;
                z-index: 98;
                top: 40px;
                left: -105px;
                border: 1px solid #DDDDDD;
                border-radius: 5px;
                border-top-right-radius: 0;
                width: 237px;
                background: #fff;
                display: none;

                .uinput {
                  padding: 20px 20px;
                  padding-top: 0;
                  line-height: 20px;

                  div {
                    padding-top: 15px;
                    font-size: 13px;
                    line-height: 25px;
                    color: #666;
                  }

                  a {
                    font-weight: 700;
                    color: #fff;
                    background: #e61d00;
                    font-size: 14px;
                    display: block;
                    padding: 5px 0;
                    line-height: 18px;
                    text-align: center;
                  }

                  .join {
                    background: #f60;
                  }
                }
              }

              .acclis {
                border-top: 1px solid #ddd;
                padding: 10px 0;

                a {
                  font-size: 13px;
                  line-height: 30px;
                  display: block;
                  text-align: left;
                  padding-left: 20px;
                  color: #666;
                }

                a:hover {
                  background: #f2f2f2;
                  color: #e60500;
                }
              }
            }

            .account:hover {
              border: 1px solid #DDDDDD;
              border-bottom: 1px solid transparent;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;

              .more_acc {
                display: block;
              }
            }

            .account::after {
              content: '';
              width: 100%;
              height: 1px;
              background-color: #fff;
              position: absolute;
              bottom: 0;
              left: 0;
              z-index: 99;
            }
          }
        }
      }
    }

    .navbar-content-wide {
      max-width: 1444px;
    }
  }

  .fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    min-width: 640px;
    background: #fff;
    z-index: 999;
    border-bottom: 1px solid #e9e9e9;
    box-shadow: 0 1px 3px #e8e8e8;
    transition: all 0s ease-in-out;

    .top-head {
      width: 100%;
    }

    .logo-box {
      padding-top: 9px !important;
    }

    .right-box {
      height: 90px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .link-box {
        display: none !important;
      }
    }
  }

  .shop-info {
    width: 100%;
    height: 64px;
    background: #F7F7F7;

    .shop-info-content {
      max-width: 1200px;
      height: 64px;
      margin: 0 auto;
    }

    .shop-info-content-wide {
      max-width: 1444px;
    }
  }
}

@media #{$media-query-small} {
  .navbar {
    height: auto !important;
    margin-bottom: 8px;

    .navbar-box {
      .navbar-content {
        .logo-box {
          width: 191px;
          padding-top: 3px;
          padding-left: 11px;
        }

        .right-box {
          width: calc(100% - 224px);

          .link-box {
            height: 28px;
          }
        }
      }

      .navbar-content {
        .right-box {
          .tools-bar {
            .account {
              .more_acc {
                left: -180px !important;
              }
            }

            .cart-info,
            .account-info {
              display: none;
            }

            .my-cart-box,
            .wish-list,
            .account {
              margin-left: 8px !important;
            }

            .my-cart-box {
              .my-cart-hover-box {
                right: -1px;
                top: 48px !important;
                left: auto !important;
                border-radius: 5px 0 5px 5px !important;
              }
            }

            .my-cart-box::after {
              bottom: -9px !important;
              height: 8px !important;
            }
          }
        }

      }
    }
  }
}