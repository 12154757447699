@import './_variables.scss';

.search-bar {
  width: 65%;
  max-width: 678px;
  position: relative;
  margin-right: 40px;

  .search-bar-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 3px solid #d50b18;
    height: 100%;
    position: relative;
    z-index: 99;

    .search-input-box {
      flex: 1;

      .search-input {
        color: #666;
        font-size: 14px;
        width: 100%;
        height: 30px;
        outline: none;
        border: 0;
        padding: 5px 10px;
      }
    }

    .search-categories-box {
      position: relative;
      height: 30px;
      width: 124px;
      background-color: #fff;
      border-left: 1px solid #e5e4e4;
      padding-right: 4px;
      box-sizing: border-box;
      cursor: pointer;
      z-index: 999;

      span {
        display: block;
        width: 110px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        padding-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #e60500;
      }

      .categories-list {
        width: 161px;
        height: 0;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        top: 30px;
        left: -1px;
        z-index: 998;
        background-color: #fff;
        transition: max-height .5s ease-in-out;
        -moz-transition: max-height .5s ease-in-out;
        -webkit-transition: max-height .5s ease-in-out;
        -o-transition: max-height .5s ease-in-out;
        
      }

      .categories-list.on {
        border: 1px solid #e5e4e4;
        padding: 5px 10px;
        height: auto;
        max-height:1200px;
      }
    }

    .search-categories-box::after {
      content: "";
      display: inline-block;
      position: absolute;
      right: 0;
      top: 12px;
      height: 0;
      width: 0;
      border: 5px solid transparent;
      border-top-color: #b2b1b1;
      vertical-align: middle;
      cursor: pointer;
    }

    .search-btn-box {
      width: 34px;
      height: 30px;
      background-color: #d50b18;
      cursor: pointer;
      border-radius: 0;
      text-align: center;

      .search-icon {
        color: #fff;
        line-height: 30px;
        font-size: 22px;
      }
    }
  }

  .search-mask {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 50;
  }

  .hot-searches {
    position: absolute;
    z-index: 998;
    top: 38px;
    width: 100%;
    padding: 2px;
    border: 1px solid #e5e4e4;
    background-color: #fff;
    padding: 10px;
    display: none;

    .hot-searches_title {
      font-size: 14px;
      color: #b5b5b5;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .hot-searches_content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;


      .hot-searches_content_item {
        font-size: 14px;
        color: #feb242;
        line-height: 22px;
        margin-right: 10px;
        cursor: pointer;
      }

      .hot-searches_content_item:hover {
        text-decoration: underline;
        color: #e60500;
      }
    }
  }

  .suggestions-searches {
    position: absolute;
    z-index: 998;
    top: 38px;
    width: 100%;
    border-top: 0;
    background-color: #f60f1f;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
    padding: 2px 4px;
    display: none;
    .suggestions {
      .suggestions_title {
        height: 35px;
        line-height: 35px;
        font-size: 14px;
        padding-left: 5px;
        color: #fff;
      }

      .suggestions_content {
        .suggestions_content_item {
          display: block;
          padding: 3px;
          color: #333;
          border-bottom: 1px dotted #ccc;
          cursor: pointer;
          line-height: 30px;
          font-size: 12px;
          padding-left: 5px;
        }

        .suggestions_content_item:nth-child(2n) {
          background: none repeat scroll 0 0 #fff;
        }

        .suggestions_content_item:nth-child(2n+1) {
          background: none repeat scroll 0 0 #f2f2f2;
        }

        .suggestions_content_item:hover {
          background: #446cb3;
          color: #fff;
        }

        .active-index {
          background: #446cb3 !important;
          color: #fff !important;
        }
      }
    }

    .product {
      .product_title {
        height: 35px;
        line-height: 35px;
        font-size: 14px;
        padding-left: 5px;
        color: #fff;
      }

      .product_content {
        background-color: #fff;

        .product_content_item {
          display: flex;
          align-items: center;
          color: #333;
          border-bottom: 1px dotted #ccc;
          line-height: 30px;
          cursor: pointer;
          padding: 4px;

          .product_content_item_img {
            width: 48px;
            margin-right: 10px;
          }

          .product_content_item_desc {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            h3 {
              font-size: 14px;
              line-height: 20px;
              height: 20px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-weight:bold;
            }

            p {
              float: none;
              line-height: 16px;
              height: 16px;
              overflow: hidden;
              font-size: 12px;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .product_content_item_price {
            min-width: 42px;
            height: auto;
            color: #446cb3;
            font-weight: 700;
            margin: 0 4px;
          }
        }

        .product_content_item:hover {
          background: #446cb3;
          color: #fff;

          .product_content_item_price {
            color: #fff;
          }
        }
        .active-index {
          background: #446cb3 !important;
          color: #fff !important;
          .product_content_item_price {
            color: #fff !important;
          }
        }
      }
    }
  }

  .search-result-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    background: #FFFFFF;
    top: 33px;
    left: -3px;
    width: 880px;
    height: 544px;
    padding: 16px;
    border-radius: 0px 8px 8px 8px;
    z-index: 998;

    .close-icon {
      position: absolute;
      top: 20px;
      right: 16px;
      font-size: 16px;
    }

    .recommend-box {
      width: 216px;
      height: 100%;
      flex-shrink: 0;
      border-right: 1px solid #DDDDDD;
      overflow: hidden;

      .search-recommend {
        padding-bottom: 16px;

        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: HelveticaNeue LT 55 Roman;
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          padding-bottom: 22px;

          .clear-all {
            font-size: 12px;
            color: #666666;
            font-weight: 400;
            text-decoration: underline;
            padding-right: 10px;
            cursor: pointer;
          }
        }

        .recommend-list {

          .recommend-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-right: 10px;
            padding-bottom: 10px;

            .recommend-item-box {
              position: relative;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
 
            .recommend-text {
              font-family: HelveticaNeue LT 55 Roman;
              font-weight: bold;
              font-size: 14px;
              color: #FD8B25;
              padding-left: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .clear-item {
              font-size: 14px;
              color: #666666;
              margin-left: auto;
              flex-shrink: 0;
              cursor: pointer;
            }
          }
        }
      }


      .trending-searches {

        .title {
          font-family: HelveticaNeue LT 55 Roman;
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          padding-bottom: 22px;
        }

        .trending-list {

          .trending-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 10px;
 
            .trending-text {
              font-family: HelveticaNeue LT 55 Roman;
              font-size: 14px;
              color: #333333;
              padding-left: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .suggested-searches {

        .title {
          font-family: HelveticaNeue LT 55 Roman;
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          padding-bottom: 22px;
        }

        .suggested-list {

          .suggested-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 32px;
            padding-bottom: 10px;
 
            .suggested-text {
              font-family: HelveticaNeue LT 55 Roman;
              font-size: 14px;
              color: #333333;
              padding-left: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-right: 12px;
            }
          }

          .suggested-item:hover {

            .suggested-text {
              color: #D70B17;
            }
          }
        }
      }

    }

    .product-box {
      width: 100%;
      padding-left: 22px;

      .title {
        font-family: HelveticaNeue LT 55 Roman;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        padding-bottom: 22px;
      }
      
      .product-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .product-item {
          width: 48%;
          height: 136px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 32px;

          .product-item-content {
            display: flex;
            justify-content: space-between;

            .product-img {
              display: block;
              width: 136px;
              height: 136px;
              object-fit: cover;
            }
  
            .item-info {
              margin-left: 16px;
  
              .product-name {
                font-family: HelveticaNeue LT 55 Roman;
                font-size: 14px;
                color: #666666;
                line-height: 18px;
                white-space: normal;
                display: -webkit-box;
                -webkit-line-clamp: 2;   // 显示的行数 
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: 54px;
                word-break: break-all;
              }
              
  
              .normal-price {
                height: 26px;
                font-family: HelveticaNeue LT 55 Roman;
                font-size: 14px;
                color: #999999;
                line-height: 16px;
                text-decoration-line: line-through;
                padding-bottom: 10px;
              }
  
              .price {
                font-family: HelveticaNeue LT 55 Roman;
                font-weight: bold;
                font-size: 24px;
                color: #D70B17;
                line-height: 16px;
              }
            }
          }
        }
      }

      .view-all {
        color: #0064e4;
        text-align: right;
        text-decoration: underline;
        padding-top: 100px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .search-input {
    min-width: 240px;
  }
}

@media #{$media-query-small} {
  .product_content_item_desc {
    width: 65% !important;
  }
}

@media #{$media-query-medium} {
  .search-bar {
    margin-right: 0;
  }
}