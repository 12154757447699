@import './_variables.scss';
.menu-box {
  width: 100%;
  height: 38px;
  background: #d50b18;
  margin: 0 auto;

  .menu-content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;

    .all-categories {
      width: 244px;
      height: 38px;
      position: relative;
      z-index: 10;

      .all-categories-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 244px;
        height: 38px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        line-height: 38px;
        background: #B7131D;
        cursor: pointer;
        padding: 0 20px;

        .icon-menu {
          font-size: 18px;
          font-weight: 600;
          padding-right: 12px;
        }

        .icon-down {
          font-size: 16px;
          margin-left: auto;
          transition: transform 0.3s;
        }
      }

      .categories-list {
        display: none;
        width: 100%;
        position: absolute;
        top: 38px;
        background: #ffffff;
        border-top: 0px;
        z-index: 500;

        .categories-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 30px;
          cursor: pointer;
          padding: 4px 4px 4px 14px;

          .item-title {
            display: block !important;
            width: 100%;

            .title-content {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
  
              .icon-cate {
                font-size: 18px;
                font-weight: 600;
                color: #d50b18;
                margin: 0px 5px;
              }
    
              .cate-name {
                font-size: 14px;
                font-weight: 500;
                color: #000000;
                padding-left: 4px;
              }
            }
          }

          .category-detail {
            position: absolute;
            top: 0;
            left: 243px;
            width: 560px;
            min-height: 572px;
            background: #ffffff;
            z-index: 999;
            padding: 10px 15px 10px 0px;
            clear: both;
            cursor: auto;
            visibility: hidden;

            .category-detail-content {
              position: absolute;
              top: 0;
              left: 0;
              width: 560px;
              min-height: 572px;
              background: #ffffff;
              border: 1px solid #e60500;
              border-top: 0px;
              z-index: 999;
            }
  
            .categories-box {
              position: absolute;
              width: 180px;
              float: left;
              padding-left: 15px;
              padding-bottom: 12px;
              cursor: pointer;
  
              .category-title {
                display: block;
                font-size: 14px;
                font-weight: 600;
                color: #474646;
                text-decoration: none;
                line-height: 24px;
                -webkit-touch-callout: none;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .sub-category {
                font-size: 12px;
                color: #656D78;
                line-height: 24px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .sub-category:hover {
                background: #f1f1f1;
              }
            }
          }
          .category-detail-spin {
            position: absolute;
            top: 0;
            left: 244px;
            width: 558px;
            height: 572px;
            background: #ffffff;
            z-index: 1000;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .categories-item:hover {
          background: #d50b18;

          .item-title {
            .title-content {
              .icon-cate {
                color: #ffffff;
              }
              .cate-name {
                color: #ffffff;
              }
            }
          }
          .category-detail {
            visibility: visible;
          }
        }
        .categories-item:first-child {
          margin-top: 10px;
        }
        .categories-item:last-child {
          margin-bottom: 22px;
        }
      }
    }

    .all-categories:hover {

      .all-categories-title {

        .icon-down {
          transform: rotate(180deg);
        }
      }

      .categories-list {
        display: block;
      }
    }

    .show {
      display: block;

      .all-categories-title {

        .icon-down {
          transform: none !important;
        }
      }

      .categories-list {
        display: block;
      }
    }

    .menu-list {
      display: flex;
      justify-content: space-between;
      padding-left: 106px;

      a {
        display: block;
        font-size: 14px;
        color: #fff;
        font-weight: 700;
        line-height: 38px;
        padding: 0px 40px;
        white-space: nowrap;
      }
      a:hover {
        background: #bc0700;
      }
    }

    .nav-doll {
      position: absolute;
      top: -116px;
      left: -90px;
      z-index: 999;
    }

    @media (max-width: 1630px) {
      .nav-doll {
        display: none;
      }
    }
  }

  .menu-content-wide {
    max-width: 1444px;
  }
}

@media screen and (max-width: 1560px) and (min-width: 1024px){
  .menu-box{
    .menu-content {
      width: calc(100% - 120px);
      .menu-list {
        padding-left: 2vw;
        a {
          padding: 0px 2.25vw;
        }
      }
    }
  }
}

@media #{$media-query-small} {
    .menu-box{
      .menu-content {
        .all-categories {
          width: 140px;

          .category-detail {
            left: 140px !important;
          }

          .all-categories-title {
            width: 160px;
            .icon-menu {
              display: none;
            }
            .icon-down {
              margin-left: 4px;;
            }
          }

          .categories-list {
            left: 20px;
            padding-left: 2px;
            padding-bottom: 12px;
            .categories-item {
              padding: 4px;
              height: 26.7px;

              .item-title {
                .title-content {
                  .icon-cate {
                    display: none;
                  }
                  .cate-name {
                    padding-left: 0;
                  }
                }
              }
            }
          }
          
        }
        .menu-list {
          padding-left: 50px;
          a {
            padding: 0px 1.75vw;
            font-size: 12px;
          }
        }
      }
    }
}